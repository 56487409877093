import { FormattedMessage } from "react-intl";
import { format } from "date-fns";
import useRequests from "Hooks/useRequests";
import React, { useState } from "react";
import Swal from "sweetalert2";

function Already() {
  const { postWithTokenAuth0 } = useRequests();
  const [data, setData] = useState({ timeZone: new Date().getTimezoneOffset(), guests: [] });
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);

  function handleOnChangedataBody(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  async function sendToData(body) {
    if (body.title === "") {
      setMessageAlert("ingresa todos los campos");
      setAlertIsOpen(true);
      setTimeout(() => {
        setAlertIsOpen(false);
      }, 3000);
    }

    if (body.title !== "") {
      const request = await postWithTokenAuth0(
        `${process.env.REACT_APP_URL_API_MNG}/schedules`,
        body
      );
      if (request.response.status === 200) {
        Swal.fire({
          text: "En poco segundos seras redirigido al salón",
          icon: "success",
          timer: 3000,
          customClass: "alert-popup",
          showConfirmButton: false,
        }).then(() => {
          window.location = request.result.url;
        });
      }
    }
  }

  function handleClickCreate() {
    const body = {
      date: format(new Date(), "yyyy-MM-dd"),
      hour: `${new Date().getHours()}:${new Date().getMinutes()}`,
      minutes: 60,
      title: data.title ? data.title : "",
      guests: [],
      time: {
        minutes: data.timeZone,
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    sendToData(body);
  }

  return (
    <div>
      <div>
        <p>
          <b>
            <FormattedMessage id="inputCalendar.inputTitle" />
          </b>
        </p>
        <input type="text" className="input-title" name="title" onChange={handleOnChangedataBody} />
      </div>
      <div>
        {alertIsOpen && <div className="mt-4 border rounded bred">{messageAlert}</div>}
        <div className="buttons-already">
          <button type="submit" className="rounded button" onClick={handleClickCreate}>
            <FormattedMessage id="labels.btnStart" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Already;
