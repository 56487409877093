import PropTypes from "prop-types";
import "./Calendar.css";
import ReactDOM from "react-dom";
import { CSVLink } from "react-csv";
import useRequests from "Hooks/useRequests";
// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { format } from "date-fns";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useContext, useState } from "react";
import { langContext, LangProvider } from "context/Lang";
import { FormattedMessage, useIntl } from "react-intl";
import Swal from "sweetalert2";
import { Auth0Provider } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Already from "./Already";

function Calendar({ header, ...rest }) {
  const [controller] = useMaterialUIController();
  const { getWithTokenAuth0 } = useRequests();
  const { locale } = useContext(langContext);
  const { darkMode } = controller;
  const [send, setSend] = useState(false);
  const intl = useIntl();
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "custom",
    "custom-success",
  ];

  async function handleClickEvent(event) {
    const listAttendes = await getWithTokenAuth0(
      `${process.env.REACT_APP_URL_API_WEBINARS}/events/${event.room.split("/")[3]}/attendees`
    );
    const recordings = await getWithTokenAuth0(
      `${process.env.REACT_APP_URL_API_WEBINARS}/webinars/${event.room.split("/")[3]}/recordings`
    );

    const headers = [
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Company", key: "company" },
    ];
    Swal.fire({
      title: intl.formatMessage({ id: "inputCalendar.info" }),
      html: `<div class="popup-content">
      <div class="head-popup">${format(new Date(event.dateOriginal), "d MMM yyyy hh:mm")}-</div>
      <div class="head-popup">${format(new Date(event.endDatetime), "d MMM yyyy hh:mm")} ${
        event.minutes
      } min</div>
    <div class="mt-4"><p><b>${intl.formatMessage({
      id: "inputCalendar.inputTitle",
    })} </b><br><span>${event.name}</span></p></div>
    <div id="list-registered" class="list-register"></div>
    </div>`,
      didOpen: () => {
        ReactDOM.render(
          <div>
            <div className="recordings">
              {intl.formatMessage({ id: "inputCalendar.listRecordings" })}
              <div className="box-recordings">
                {recordings.result &&
                  recordings.result.map((recording) => (
                    <div key={recording.number} className="item-recording">
                      <a href={recording.file}>
                        {intl.formatMessage({ id: "inputCalendar.Recording" })} {recording.number}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <div className="content-listr">
              {intl.formatMessage({ id: "inputCalendar.listRegistered" })}
              <CSVLink
                data={listAttendes.result ? listAttendes.result : []}
                headers={headers}
                filename="attendees_list_.csv"
                className="btn-download"
              >
                {intl.formatMessage({ id: "inputCalendar.btnDownload" })}
              </CSVLink>
            </div>
          </div>,

          document.getElementById("list-registered")
        );
      },
      willClose: () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("list-registered"));
      },
      showDenyButton: false,
      confirmButtonText: intl.formatMessage({ id: "buttons.confirm" }),
      confirmButtonColor: "#1a73e8",
      customClass: "alert-popup",
      showCloseButton: true,
    });
  }

  function handleButtonReady() {
    Swal.fire({
      title: intl.formatMessage({ id: "inputCalendar.intanAppoinment" }),
      showConfirmButton: false,
      html: '<div id="my-component" class="popup-content"></div>',
      showCloseButton: true,
      didOpen: () => {
        ReactDOM.render(
          <Auth0Provider
            domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
            clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
            redirectUri={window.location.origin}
            audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
            scope="read:current_user update:current_user_metadata"
          >
            <LangProvider>
              <Already send={send} />
            </LangProvider>
          </Auth0Provider>,
          document.getElementById("my-component")
        );
      },
      willClose: () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("my-component"));
      },
      customClass: "alert-popup",
    }).then(() => setSend(true));
  }

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: validClassNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={header.title || header.date ? 2 : 0} px={2} lineHeight={1}>
        {header.title ? (
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </MDTypography>
        ) : null}
        {header.date ? (
          <MDTypography component="p" variant="button" color="text" fontWeight="regular">
            {header.date}
          </MDTypography>
        ) : null}
      </MDBox>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          locale={locale === "es-MX" ? "es" : ""}
          {...rest}
          customButtons={{
            buttonReady: {
              text: "¡Ya!",
              click: () => handleButtonReady(),
            },
            myCustomButton: {
              text: (
                <Link to="/create-schedule">
                  <FormattedMessage id="buttons.create" />
                </Link>
              ),
            },
            createWebinar: {
              text: (
                <Link to="/create-webinar">
                  <FormattedMessage id="buttons.create" /> WEBINAR
                </Link>
              ),
            },
          }}
          headerToolbar={{
            start: "title", // will normally be on the left. if RTL, will be on the right
            end: `createWebinar today prev,next`, // will normally be on the right. if RTL, will be on the left
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
          eventClick={(e) => {
            handleClickEvent(e.event.extendedProps);
          }}
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
