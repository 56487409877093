import React, { useState, useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { format } from "date-fns";
import { enAU, es } from "date-fns/locale";
import { langContext } from "context/Lang";
import { useIntl } from "react-intl";

function DropdownRepeat({ setRepeat, repeat, day }) {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef(null);
  const intl = useIntl();
  const { locale } = useContext(langContext);
  const repeats = {
    0: intl.formatMessage({ id: "inputCalendar.repeatNotRepeat" }),
    1: intl.formatMessage({ id: "inputCalendar.repeatEveryDay" }),
    2: intl.formatMessage({ id: "inputCalendar.repeatWeekly" }),
    3: intl.formatMessage({ id: "inputCalendar.repeatWorkingDay" }),
  };

  useEffect(() => {
    const handleCloseDiv = (event) => {
      if (isOpen && divRef.current && !divRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleCloseDiv);
    return () => {
      document.removeEventListener("click", handleCloseDiv);
    };
  }, [isOpen]);

  return (
    <div className="drop-down" style={{ marginTop: "15px" }}>
      <div className="drop-down-select">
        <div className="text" style={{ fontSize: "12px" }}>
          {repeats[repeat]}
        </div>
        <button className="drop-icon" type="submit" onClick={() => setIsOpen(!isOpen)}>
          <Icon>keyboard_arrow_down</Icon>
        </button>
      </div>
      {isOpen && (
        <div className="drop-down-content" ref={divRef}>
          <button type="submit" className="drop-down-item" onClick={() => setRepeat(0)}>
            {repeats[0]}
          </button>
          <button type="submit" className="drop-down-item" onClick={() => setRepeat(1)}>
            {repeats[1]}
          </button>
          <button type="submit" className="drop-down-item" onClick={() => setRepeat(2)}>
            {repeats[2]}{" "}
            {format(day, "EEEE", {
              locale: locale === "es-MX" ? es : enAU,
            })}
          </button>
          <button type="submit" className="drop-down-item" onClick={() => setRepeat(3)}>
            {repeats[3]}
          </button>
        </div>
      )}
    </div>
  );
}
DropdownRepeat.defaultProps = {
  setRepeat: "",
  repeat: "",
  day: "",
};
// Typechecking props for the Calendar
DropdownRepeat.propTypes = {
  setRepeat: PropTypes.func,
  repeat: PropTypes.number,
  day: PropTypes.instanceOf(Date),
};
export default DropdownRepeat;
