import { useAuth0 } from "@auth0/auth0-react";
import { dataUserContext } from "context/ContextUser";
import { langContext } from "context/Lang";
import useRequests from "Hooks/useRequests";
import React, { useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import "./Header.css";

function Header({ ...rest }) {
  const { logout, isAuthenticated } = useAuth0();
  const { getWithTokenAuth0 } = useRequests();
  const { establecerLenguaje, locale } = useContext(langContext);
  const { setUserData } = useContext(dataUserContext);

  useEffect(() => {
    if (isAuthenticated)
      (async () => {
        const getData = await getWithTokenAuth0(`${process.env.REACT_APP_URL_API_MNG}/user`);
        setUserData(getData.result);
        rest.setStatus(getData.response.status);
      })();
  }, [isAuthenticated]);

  return (
    <div>
      <header className="header-default">
        <div className="container-nav flex container">
          <a href="/" className="logo">
            <img
              className="logo-header"
              src={`${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`}
              alt="logo-virtuales"
            />
          </a>
          <ul className="nav nav-default">
            <li>
              <FormattedMessage id="header.welcome" defaultMessage="Bienvenido(a) " />
            </li>
            <li className="">
              <Link to="/">
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_CONTACT_IMG}/faq.svg`}
                  alt="faq"
                  width="55"
                  height="55"
                />
              </Link>
            </li>
            <li>
              <div className="lenguajes">
                <div
                  onClick={() => establecerLenguaje("en-US")}
                  onKeyDown={() => establecerLenguaje("en-US")}
                  role="button"
                  tabIndex="0"
                >
                  <img
                    className="imgfaq"
                    src={`${process.env.REACT_APP_CONTACT_IMG}/reino-unido.png`}
                    alt="faq"
                    width="25"
                    height="25"
                    style={
                      locale === "en-US"
                        ? {
                            border: "1px soli white",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "2px",
                          }
                        : {}
                    }
                  />
                </div>
                <div
                  onClick={() => establecerLenguaje("es-MX")}
                  onKeyDown={() => establecerLenguaje("es-MX")}
                  role="button"
                  tabIndex="0"
                >
                  <img
                    className="imgfaq"
                    src={`${process.env.REACT_APP_CONTACT_IMG}/colombia.png`}
                    alt="faq"
                    width="25"
                    height="25"
                    style={
                      locale === "es-MX"
                        ? {
                            border: "1px soli white",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "2px",
                          }
                        : {}
                    }
                  />
                </div>
              </div>
            </li>
            {isAuthenticated && (
              <li>
                <button
                  className="button-logout"
                  type="button"
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin,
                    })
                  }
                >
                  <FormattedMessage id="btn.logout" />
                </button>
              </li>
            )}
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Header;
