import { Card, Grid } from "@mui/material";
import useRequests from "Hooks/useRequests";
import MDBox from "components/MDBox";
import UpdateCalendar from "components/UpdateCalendar";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function ViewUpdatedSchedule() {
  const { id } = useParams();
  const { getWithTokenAuth0 } = useRequests();
  const [dataUpdated, setDataUpdated] = useState(false);
  const dataTemp = {
    id: "aa911523-b014-4a92-a659-f0a425c39bd6",
    title: "Cargando...",
    logo: "https://resources.virtuales.io/img/tumarca.svg",
    date: "2023-07-31T00:30:00.000Z",
    date_schedule: "2023-07-31T00:30:00+00:00",
    minutes: 450,
    guests: [
      {
        name: "Cargando...",
        email: "Cargando...",
      },
    ],
    repeat: {
      type: 0,
      end: "2023-08-05",
    },
    start: "2023-07-31 00:30:00",
    end: "2023-07-31 00:00:00",
    startDatetime: "2023-07-31 00:30:00",
    endDatetime: "2023-07-31 00:00:00",
    name: "Cargando...",
  };

  const getDataSchedules = async () => {
    const getDataWebinars = await getWithTokenAuth0(
      `${process.env.REACT_APP_URL_API_WEBINARS}/webinars/${id}`
    );

    const dateInit = getDataWebinars.result.date.replace("Z", "");
    const name = getDataWebinars.result.title;
    const start = format(new Date(dateInit), "yyyy-MM-dd HH:mm:ss");
    const end = format(
      new Date(dateInit).setMinutes(
        new Date(dateInit).getMinutes() + (getDataWebinars.result.minutes || 60)
      ),
      "yyyy-MM-dd HH:mm:ss"
    );
    const startDatetime = start;
    const endDatetime = end;
    setDataUpdated({ ...getDataWebinars.result, start, end, startDatetime, endDatetime, name });
  };

  useEffect(() => {
    getDataSchedules();
  }, []);

  return (
    <MDBox pt={4} className="container">
      <Grid item xs={3} xl={12} sx={{ height: "max-content" }}>
        <Card sx={{ height: "95%" }} className="-my-1">
          {dataUpdated && <UpdateCalendar dataUpdated={dataUpdated} />}
          {!dataUpdated && <UpdateCalendar dataUpdated={dataTemp} />}
        </Card>
      </Grid>
    </MDBox>
  );
}

export default ViewUpdatedSchedule;
