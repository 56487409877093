import useRequests from "Hooks/useRequests";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { format, startOfToday } from "date-fns";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import EventCalendar from "examples/Calendar";
import NextEvents from "..";

function ViewCalendar() {
  const { getWithTokenAuth0, deleteWithTokenAuth0 } = useRequests();
  const today = startOfToday();
  const currentDay = format(today, "yyyy-MM-dd");
  const [selectedDay, setSelectedDay] = useState(today);
  const [dataEvents, setDataEvents] = useState(false);
  const [dataCalendar, setDataCalendar] = useState([]);
  const intl = useIntl();

  function colorItem(dateInit, minutes) {
    if (
      new Date().getTime() >= new Date(dateInit).getTime() &&
      new Date().getTime() >= new Date(new Date(dateInit).setMinutes(minutes)).getTime()
    ) {
      return "light";
    }
    if (
      new Date().getTime() <= new Date(dateInit).getTime() &&
      new Date().getTime() <= new Date(new Date(dateInit).setMinutes(minutes)).getTime()
    ) {
      return "custom";
    }
    if (
      new Date().getTime() >= new Date(dateInit).getTime() &&
      new Date().getTime() <= new Date(new Date(dateInit).setMinutes(minutes)).getTime()
    ) {
      return "custom-success";
    }
    return "light";
  }

  function expandDateRange(objects) {
    const newArray = [];

    objects.forEach((object) => {
      if (object.repeat === 0) {
        const newObject = { ...object };
        newObject.dateOriginal = object.date.toString().replace("Z", "");
        newArray.push(newObject);
      } else {
        const date = new Date(object.date.toString().replace("Z", ""));
        const endDate = new Date(object.end_repeat_date).setDate(
          new Date(object.end_repeat_date).getDate() + 1
        );

        while (date <= endDate) {
          const newObject = { ...object };
          newObject.date = new Date(date);
          newObject.dateOriginal = object.date.toString().replace("Z", "");

          if (object.repeat === 1) {
            newArray.push(newObject);
          }
          if (object.repeat === 2 && date.getDay() === new Date(object.date).getDay()) {
            newArray.push(newObject);
          }
          if (object.repeat === 3 && date.getDay() !== 6 && date.getDay() !== 0) {
            newArray.push(newObject);
          }

          date.setDate(date.getDate() + 1);
        }
      }
    });

    return newArray;
  }

  const getDataSchedules = async () => {
    const getDataWebinars = await getWithTokenAuth0(
      `${process.env.REACT_APP_URL_API_WEBINARS}/webinars`
    );
    const r = expandDateRange(getDataWebinars.result);

    const dataWebinars = [];
    const dataCalendarWebinars = [];
    r.forEach((schedule) => {
      const dateInit = schedule.date.toString().replace("Z", "");
      const dateEnd = schedule.date.toString().replace("Z", "");

      const name = schedule.title;
      const updatedSchedule = {
        ...schedule,
        title: `${schedule.repeat === 1 ? "(D)" : ""} ${schedule.repeat === 2 ? "(S)" : ""} ${
          schedule.repeat === 3 ? "(DL)" : ""
        } ${schedule.title}`,
        room: schedule.url,
      };
      delete updatedSchedule.url;
      const start = format(new Date(dateInit), "yyyy-MM-dd HH:mm:ss");
      const end = format(
        new Date(dateEnd).setMinutes(new Date(dateEnd).getMinutes() + schedule.minutes),
        "yyyy-MM-dd HH:mm:ss"
      );
      const className = colorItem(dateInit, schedule.minute);
      const startDatetime = start;
      const endDatetime = end;
      const s = { ...updatedSchedule, start, end, startDatetime, endDatetime, name, className };

      const search = dataWebinars.findIndex((item) => item.id === schedule.id);
      if (search === -1) {
        dataWebinars.push(s);
      } else if (new Date(s.start).getTime() > new Date().getTime()) {
        const newMinutes = new Date(dateInit).getMinutes();
        const copySchedule = { ...s };
        delete copySchedule.endDatetime;

        dataWebinars[search] = {
          ...copySchedule,
          endDatetime: format(
            new Date(s.end_repeat_date).setMinutes(newMinutes + s.minutes),
            "yyyy-MM-dd HH:mm:ss"
          ),
        };
      }

      dataCalendarWebinars.push(s);
    });

    setDataCalendar([...dataCalendarWebinars]);
    setDataEvents([...dataWebinars]);
  };

  const handleDelete = async (idSchedule) => {
    Swal.fire({
      title: "Alerta!",
      text: "¿Estas seguro que desear eliminar la agenda?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: intl.formatMessage({ id: "buttons.confirm" }),
      denyButtonText: "Cancelar",
      denyButtonColor: "red",
      confirmButtonColor: "#1a73e8",
      customClass: "alert-popup",
    }).then(async (response) => {
      if (response.isConfirmed) {
        const result = await deleteWithTokenAuth0(
          `${process.env.REACT_APP_URL_API_WEBINARS}/webinars/${idSchedule}`
        );
        if (result.response.status === 200) {
          Swal.fire({
            title: "Se elimino correctamente",
            timer: 2000,
            icon: "success",
            showConfirmButton: false,
          }).then(() => getDataSchedules());
        }
      }
    });
  };

  useEffect(() => {
    getDataSchedules();
  }, []);

  const currentD = new Date().getTime();
  const selectedDayMeetings =
    dataEvents &&
    dataEvents.filter((schedule) => new Date(schedule.end).getTime() >= currentD && schedule);

  localStorage.setItem("schedules", JSON.stringify(selectedDayMeetings));

  return (
    <div>
      <MDBox pt={4} className="container">
        <Grid container spacing={3}>
          <Grid item xs={3} xl={9} sx={{ height: "max-content" }}>
            <EventCalendar
              setSelectedDay={setSelectedDay}
              initialView="dayGridMonth"
              initialDate={currentDay}
              events={dataCalendar}
              selectable
              editable
            />
          </Grid>

          <Grid item xs={12} xl={3}>
            <MDBox mb={3}>
              <NextEvents
                meetings={selectedDayMeetings}
                selectedDay={selectedDay}
                handleDelete={handleDelete}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default ViewCalendar;
